import "./App.css";
import Logo from "./Logo/Logo.js";
import Content from "./Content/Content.js";

function App() {
  return (
    <div className="App">
      <Logo />
      <Content />
    </div>
  );
}

export default App;
