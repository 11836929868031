import styles from "./TextItems.module.scss";

export default function Item7() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>2.6.2016.</b> Iz originalnog postava sve su fotografije do sad bile
          skinute od strane nepoznatih sudionika (djeca?). Brojevi 26 i 17
          prkose, u prizemlju u dvorištu nalazim fotografije u travi (22, 7, 23,
          2 i 12). Budućnost…
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>2 June 2016</b> All the photos from the original setup have been
          taken down by now, by unknown participants (kids?). The #26 and 17 are
          still holding, on the ground floor I find photos in the grass (22, 7,
          23, 2 and 12). Future….
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item7}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
