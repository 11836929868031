import styles from "./GalleryView.module.scss";
import Close from "./Close.js";

export default function GalleryView(props) {
  return (
    <div className={styles.container} onClick={props.onClose}>
      <div className={styles.item}>
        <img src={props.src} alt={props.className} />
        <div className={styles.text}>{props.title}</div>
        <Close className={styles.close} />
      </div>
    </div>
  );
}
