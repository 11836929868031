import styles from "./TextItems.module.scss";

export default function Item2() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>29.4.2016.</b> Postavljena pop-up izložba u napuštenoj kući Disco
          Pavle, u Facchinettijevoj ulici u Puli. Uz pomoć Eve ČaČe, u prostor
          je instalirano dvadeset devet fotografija u A4 formatu. Fotografije su
          fiksirane na mjesta gdje su prvotno fotografirane u periodu od osam
          godina unatrag. Kroz gerila eksperiment nastoji se potaknuti
          komunikacija s prolaznicima te se predviđa vođenje dnevnika
          postojanosti izložbe.
        </li>
        <li>
          <b>9.5.2016.</b> Fotka najbliže ulici (br. 5) bačena u grmlje, fotka
          broj 13 otkačena i visi samo na jednom čavlu, stanje ostalih
          nepromijenjeno od postava.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>29 April 2016</b> A pop-up exhibition has been set-up in an
          abandoned house Disco Pavle, Facchinettijeva st. in Pula, Croatia.
          With the help of Eva Čače, there have been installed twenty-nine
          photos in A4 format. Photos are fixed on the spots where they were
          initially taken during the last eight years. Through guerilla
          experiment it is striven to encourage a communication with the passers
          and the plan is to keep a diary of events.
        </li>
        <li>
          <b>9 May 2016</b> The photo closest to the street (#5) was thrown into
          the bushes, #13 unhooked and hanging only on one nail, the rest is
          unchanged.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item2}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
