import styles from "./TextItems.module.scss";

export default function Item14() {
  function getCroText() {
    return (
      <div className={styles.cro}>
        Projekt je započet 2016 godine i smatra se nezaključenim. Veliko hvala
        Evi Čače i Matiji Miletiću za pomoć i podršku u realizaciji i provođenju
        ideja.
      </div>
    );
  }

  function getEngText() {
    return (
      <div className={styles.eng}>
        The project started in 2016 and is considered ongoing. A big thank you
        to Eva Čače and Matija Miletić for the help and support in implementing
        and pursuing ideas.
      </div>
    );
  }

  return (
    <div className={styles.item14}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
