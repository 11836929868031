import styles from "./ImgGroupItems.module.scss";

export default function Item6() {
  return (
    <div className={styles.group}>
      <img
        src="/images/groups/6/6_1.webp"
        className={styles.group6img1}
        alt="img1.group_6"
      />
      <img
        src="/images/groups/6/6_2.webp"
        className={styles.group6img2}
        alt="img2.group_6"
      />
    </div>
  );
}
