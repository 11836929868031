import styles from "./TextItems.module.scss";

export default function Item3() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>19.5.2016.</b> Fale sve fotografije s kata izuzev broja 26, njih
          trinaest, te fotke br. 11, 10 i 28 iz prizemlja.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>19 May 2016</b> All the thirteen photos from the 1st floor are
          missing, except #26, and #11, 10 and 28 from the ground floor.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item3}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
