import styles from "./Maps.module.scss";
import ClickableImageItem from "./ClickableImageItem.js";

export default function Map2() {
  return (
    <div className={styles.map2}>
      <img
        src="/images/maps/tlocrtprizemlje.webp"
        className={styles.map2}
        alt="tlocrtprizemlje"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/25_JPG.webp"
        className={styles.img25}
        alt="tlocrtprizemlje.prizemlje_25"
        title="28"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/17_JPG.webp"
        className={styles.img17}
        alt="tlocrtprizemlje.prizemlje_17"
        title="12"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/18_JPG.webp"
        className={styles.img18}
        alt="tlocrtprizemlje.prizemlje_18"
        title="10"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/19_JPG.webp"
        className={styles.img19}
        alt="tlocrtprizemlje.prizemlje_19"
        title="14"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/20_JPG.webp"
        className={styles.img20}
        alt="tlocrtprizemlje.prizemlje_20"
        title="6"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/16a_JPG.webp"
        className={styles.img16}
        alt="tlocrtprizemlje.prizemlje_16"
        title="9"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/13b_JPG.webp"
        className={styles.img13}
        alt="tlocrtprizemlje.prizemlje_13"
        title="8"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/21_JPG.webp"
        className={styles.img21}
        alt="tlocrtprizemlje.prizemlje_21"
        title="21"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/22_JPG.webp"
        className={styles.img22}
        alt="tlocrtprizemlje.prizemlje_22"
        title="23"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/30c_JPG.webp"
        className={styles.img30}
        alt="tlocrtprizemlje.prizemlje_30"
        title="2"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/23a_JPG.webp"
        className={styles.img23}
        alt="tlocrtprizemlje.prizemlje_23"
        title="7"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/24_JPG.webp"
        className={styles.img24}
        alt="tlocrtprizemlje.prizemlje_24"
        title="22"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/15_JPG.webp"
        className={styles.img15}
        alt="tlocrtprizemlje.prizemlje_15"
        title="11"
      />
      <ClickableImageItem
        src="/images/maps/prizemlje/14a_JPG.webp"
        className={styles.img14}
        alt="tlocrtprizemlje.prizemlje_14"
        title="17"
      />
    </div>
  );
}
