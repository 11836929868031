import styles from "./TextItems.module.scss";

export default function Item9() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>Rujan 2016.</b> Nakon posla volim doći ovdje i praviti se da ne
          vidim što je od Aftera ostalo.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>September 2016</b> I love to come here after work and pretend I
          don’t see what’s left of After.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item9}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
