import styles from "./TextItems.module.scss";

export default function Item8() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>3.6.2016.</b> Zadatak je postaviti/vratiti pet fotografija.
          Postavila brojeve 12, 23, 2 i 7. Broj 22 bilo je nemoguće ponovo
          postaviti zbog nezgodne pozicije i krtosti zida (prenoćit će kod
          mene).
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>3 June 2016</b> The task is to put back five photos. I set the #12,
          23, 2 and 7 up. No 22 was impossible to nail because of the
          inconvenient position and brittle wall (it will stay at my place).
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item8}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
