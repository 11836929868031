import styles from "./TextItems.module.scss";

export default function Item12() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>29.4.2017.</b> Točno godinu dana nakon postava, u Fažani na
          Vertbrandtu II prilazi mi Edo Edward Norton i govori mi: “Ša Mia
          Wallace, srušili su nam Disco Pavle…”. Ja i Dora ajmeamo. “Da, da, sve
          ja znam…”, ponavlja. Ja prekidam razgovor.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>29 April 2017</b> Exactly a year from the setup, at an event in
          Fažana, Edo Edward Norton approaches me and says:”What’s up Mia
          Wallace, they tore down our Disco Pavle…”. Me and Dora omg. “Yes, yes,
          I know everything…”, he repeats. I break off the conversation.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item12}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
