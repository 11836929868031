import styles from "./ImgGroupItems.module.scss";

export default function Item7() {
  return (
    <div className={styles.group}>
      <img
        src="/images/groups/7/7_1.webp"
        className={styles.group7img1}
        alt="img1.group_7"
      />
      <img
        src="/images/groups/7/7_2.webp"
        className={styles.group7img2}
        alt="img2.group_7"
      />
      <img
        src="/images/groups/7/7_3.webp"
        className={styles.group7img2}
        alt="img3.group_7"
      />
    </div>
  );
}
