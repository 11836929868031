import styles from "./TextItems.module.scss";

export default function Item4() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>23.5.2016.</b> Nedostaje fotka br. 14 iz prizemlja.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>23 May 2016</b> Photo #14 missing from the ground floor.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item4}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
