import styles from "./ImgGroupItems.module.scss";

export default function Item4() {
  return (
    <div className={styles.group}>
      <img
        src="/images/groups/4/4_1.webp"
        className={styles.group4img1}
        alt="img1.group_4"
        title="#23"
      />
      <img
        src="/images/groups/4/4_2.webp"
        className={styles.group4img2}
        alt="img2.group_4"
        title="photos in the grass"
      />
      <img
        src="/images/groups/4/4_3.webp"
        className={styles.group4img2}
        alt="img3.group_4"
        title="found photos"
      />
      <img
        src="/images/groups/4/4_4.webp"
        className={styles.group4img2}
        alt="img4.group_4"
        title="#7"
      />
    </div>
  );
}
