import styles from "./TextItems.module.scss";

export default function Item6() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <p>
            <b>30.5.2016. </b>
            Stigla sam kući ujutro a mami je susjed Cukerić dao pet fotografija
            za koje je rekao da mu ih je netko ubacio u vrt. Fotke su vidno
            pokidane s čavala ali nisu uništene na ijedan drugi način. Radi se o
            fotografijama koje su nestale iz postava zadnji put kada sam
            posjetila mjesto (br. 26, 12, 8, 9, 5).
          </p>
          <p>
            &nbsp; Razmišljam što napraviti s fotkama koje su mi vraćene, ponovo
            ih postaviti i ako da, treba li to biti na istom mjestu? Uz
            preostalih sedam netaknutih, ovih pet svejedno ne bi činilo većinu
            originalnog postava.
          </p>
          <p>
            &nbsp; U namjeri da vraćene izloške postavim na mjesto, u prizemlju
            primjećujem manjak br. 21, 8 i 6 te br. 17 u travi dvorišta u
            prizemlju. Vraćam brojeve 6, 17, 8, 5, 26 i 12 na njihova mjesta
            prilikom čega dolazi susjeda jer joj “kuckam na zid” i pita me znam
            li da je ovo privatan posjed, imam li dozvolu i što točno radim.
            Odgovaram joj da radim eksperiment jer živim dvije kuće niz ulicu i
            da nisam sigurna je li prostor privatan jer je ostavljen da zjapi
            otkad znam za sebe. Zid na koji sam zabijala fotke nije zid njene
            kuće jer je vidno dvoslojan i derutan, od istog materijala kao
            cijela ruševina Disco Pavle. Kažem joj da me ne brine jer se ovdje
            odavno skupljaju razne marginalne skupine i da moj projekt nije taj
            koji treba prijaviti u cijeloj priči. Žena kaže da je vidjela djecu
            kako čupaju fotografije sa zida.
          </p>
        </li>
        <li>
          Broj preostalih fotografija: 10/29 od toga originalno na zidu ostaju
          samo br. 22, 7, 23 i 2.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <p>
            <b>30 May 2016 </b> I came home in the morning to find that our
            neighbor Cukerić gave my mom five photos saying that somebody left
            those his garden. Photos are visibly torn from the nails but
            otherwise undamaged. Those are the photos that went missing last
            time I visited the place (#26, 12, 8, 9, 5).
          </p>
          <p>
            &nbsp; I am thinking what to do with those that I got back, if I
            should set them up again and if so, should they be placed in the
            same spot? Along the last seven untouched, five of these
            nevertheless wouldn’t make the most of the original setup.
          </p>
          <p>
            &nbsp; With intention to return the exhibits at their posts, on the
            ground floor I notice the absence of #21, 8 and 6, and #17 in the
            grass. I put back #6, 17, 8, 5, 26 and 12 at their places during
            what a neighbor approaches because I’m “knocking on her wall” and
            asks me if I am aware that this is a private property, if I have a
            permit and what am I doing. I tell her about the experiment, that I
            live two houses down the street and am not sure that the ruin is a
            private property because it was left to rot at least ever since I
            was born. The wall I nailed the photos to is not a part of her house
            because it’s rotten and shabby like the rest of the Disco Pavle
            ruin. I tell her that I am not worried because this is a place that
            has been inhabited by all kinds of people and therefore my project
            could not be the one you call the cops on. Woman says she saw kids
            tearing down the photos.
          </p>
        </li>
        <li>
          Number of leftover photos: 10/29 of that originally placed only # 22,
          7, 23 and 2.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item6}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
