import styles from "./ImgItems.module.scss";

export default function ImgItem5() {
  return (
    <div className={styles.img5}>
      <img
        src="/images/5.webp"
        className={styles.img5}
        alt="img5"
        title="#5 in the bushes"
      />
    </div>
  );
}
