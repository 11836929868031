import styles from "./TextItems.module.scss";

export default function Item10() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>25.12.2016.</b> Putem do none nailazim na potpuno srušen Disco
          Pavle. Nemoguće je pristupiti prostoru ali s ulice je vidljivo da je
          građevina sravnata sa zemljom. Sve što je ostalo od <i>After</i>a je
          pjesma i fotka br. 2. Ćao.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>25 December 2016</b> On my way to my grandma I encounter a
          completely crashed Disco Pavle. It is impossible to approach the space
          but it is visible that it has been demolished to the ground. All
          that’s left of <i>After</i> is the poem and photo #2. Bye.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item10}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
