import styles from "./TextItems.module.scss";

export default function Item5() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>25.5.2016.</b> Nakon odlaska na more u prolasku primjećujem da fali
          jedina preostala fotografija s kata (br. 26) pa obilaskom saznajem da
          nedostaju br. 12, 6, 9. Izlaskom iz Disco Pavle srećem dva dječaka
          koji me pozdravljaju iako se ne poznajemo. Dvanaestogodišnjaci to
          shvate te me skrivajući se prate do ulaska u dvorište.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>25 May 2016</b> While passing near after going to the beach I
          notice the lack of the only photo from the 1st floor (#26) so touring
          I see that #12, 6 and 9 are also absent. Exiting the lot I meet two
          boys who greet me even though we don’t know each other.
          Twelve-year-olds realize it and follow me to my front yard while
          hiding.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item5}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
