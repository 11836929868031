export default function GoogleMap() {
  return (
    <iframe
      title="GME"
      style={{ border: "0px" }}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d671.350034404009!2d13.84525!3d44.848450799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477cd3472893f495%3A0xd73fc704667f3aa5!2sprilaz%20puljskih%20%C5%A1panjolskih%20boraca%2021%2C%2052100%2C%20Pula%2C%20Croatia!5e0!3m2!1sen!2sfr!4v1640969145332!5m2!1sen!2sfr"
      width="250"
      height="250"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  );
}
