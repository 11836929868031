import styles from "./ImgGroupItems.module.scss";

export default function Item1() {
  return (
    <div className={styles.group}>
      <img
        src="/images/groups/1/1_1.webp"
        className={styles.group1img1}
        alt="img1.group_1"
        title="#20"
      />
      <img
        src="/images/groups/1/1_2.webp"
        className={styles.group1img2}
        alt="img2.group_1"
        title="#29"
      />
      <img
        src="/images/groups/1/1_3.webp"
        className={styles.group1img3}
        alt="img3.group_1"
        title="#18"
      />
      <img
        src="/images/groups/1/1_4.webp"
        className={styles.group1img4}
        alt="img4.group_1"
        title="#20"
      />
      <img
        src="/images/groups/1/1_5.webp"
        className={styles.group1img5}
        alt="img5.group_1"
        title="#24,#25"
      />
      <img
        src="/images/groups/1/1_6.webp"
        className={styles.group1img6}
        alt="img6.group_1"
        title="#27"
      />
      <img
        src="/images/groups/1/1_7.webp"
        className={styles.group1img7}
        alt="img7.group_1"
        title="#15"
      />
      <img
        src="/images/groups/1/1_8.webp"
        className={styles.group1img8}
        alt="img8.group_1"
        title="#16"
      />
      <img
        src="/images/groups/1/1_9.webp"
        className={styles.group1img9}
        alt="img9.group_1"
        title="#13"
      />
      <img
        src="/images/groups/1/1_10.webp"
        className={styles.group1img10}
        alt="img10.group_1"
        title="#3"
      />
      <img
        src="/images/groups/1/1_11.webp"
        className={styles.group1img11}
        alt="img11.group_1"
        title="#19"
      />
      <img
        src="/images/groups/1/1_12.webp"
        className={styles.group1img12}
        alt="img12.group_1"
        title="#11"
      />
      <img
        src="/images/groups/1/1_13.webp"
        className={styles.group1img13}
        alt="img13.group_1"
        title="#10"
      />
      <img
        src="/images/groups/1/1_14.webp"
        className={styles.group1img14}
        alt="img14.group_1"
        title="#28"
      />
    </div>
  );
}
