import TextItem1 from "../ContentItems/TextItems/Item1";
import TextItem2 from "../ContentItems/TextItems/Item2";
import TextItem3 from "../ContentItems/TextItems/Item3";
import TextItem4 from "../ContentItems/TextItems/Item4";
import TextItem5 from "../ContentItems/TextItems/Item5";
import TextItem6 from "../ContentItems/TextItems/Item6";
import TextItem7 from "../ContentItems/TextItems/Item7";
import TextItem8 from "../ContentItems/TextItems/Item8";
import TextItem9 from "../ContentItems/TextItems/Item9";
import TextItem10 from "../ContentItems/TextItems/Item10";
import TextItem11 from "../ContentItems/TextItems/Item11";
import TextItem12 from "../ContentItems/TextItems/Item12";
import TextItem13 from "../ContentItems/TextItems/Item13";
import TextItem14 from "../ContentItems/TextItems/Item14";

import ImgItem1 from "../ContentItems/ImgItems/Item1";
import ImgItem2 from "../ContentItems/ImgItems/Item2";
import ImgItem3 from "../ContentItems/ImgItems/Item3";
import ImgItem4 from "../ContentItems/ImgItems/Item4";
import ImgItem5 from "../ContentItems/ImgItems/Item5";
import ImgItem6 from "../ContentItems/ImgItems/Item6";
import ImgItem7 from "../ContentItems/ImgItems/Item7";

import ImgGroupItem1 from "../ContentItems/ImgGroupItems/Item1";
import ImgGroupItem2 from "../ContentItems/ImgGroupItems/Item2";
import ImgGroupItem3 from "../ContentItems/ImgGroupItems/Item3";
import ImgGroupItem4 from "../ContentItems/ImgGroupItems/Item4";
import ImgGroupItem5 from "../ContentItems/ImgGroupItems/Item5";
import ImgGroupItem6 from "../ContentItems/ImgGroupItems/Item6";
import ImgGroupItem7 from "../ContentItems/ImgGroupItems/Item7";
import ImgGroupItem8 from "../ContentItems/ImgGroupItems/Item8";

import VideoGroupItem1 from "../ContentItems/VideoGroupItems/Item1";
import VideoGroupItem2 from "../ContentItems/VideoGroupItems/Item2";

import Map1 from "../ContentItems/Maps/Map1.js";
import Map2 from "../ContentItems/Maps/Map2.js";

import GoogleMap from "../ContentItems/GoogleMap/GoogleMap.js";

import styles from "./Content.module.scss";

export default function Content() {
  return (
    <div className={styles.container}>
      <ImgItem1 />
      <ImgItem2 />
      <ImgItem3 />
      <TextItem1 />
      <Map1 />
      <Map2 />
      <TextItem2 />
      <ImgItem4 />
      <ImgItem5 />
      <TextItem3 />
      <ImgGroupItem1 />
      <TextItem4 />
      <ImgGroupItem2 />
      <TextItem5 />
      <ImgGroupItem3 />
      <TextItem6 />
      <VideoGroupItem1 />
      <TextItem7 />
      <ImgGroupItem4 />
      <TextItem8 />
      <VideoGroupItem2 />
      <TextItem9 />
      <ImgGroupItem5 />
      <TextItem10 />
      <ImgGroupItem6 />
      <TextItem11 />
      <ImgGroupItem7 />
      <ImgItem6 />
      <TextItem12 />
      <ImgItem7 />
      <TextItem13 />
      <ImgGroupItem8 />
      <GoogleMap />
      <TextItem14 />
    </div>
  );
}
