import styles from "./ImgGroupItems.module.scss";

export default function Item2() {
  return (
    <div className={styles.group}>
      <img
        src="/images/groups/2/2_1.webp"
        className={styles.group2img1}
        alt="img1.group_2"
        title="#14"
      />
      <img
        src="/images/groups/2/2_2.webp"
        className={styles.group2img2}
        alt="img2.group_2"
        title="open air exhibition"
      />
    </div>
  );
}
