import React, { useState, useEffect } from "react";

import GalleryView from "./GalleryView.js";

import styles from "./Maps.module.scss";

export default function ClickableImageItem(props) {
  const [isGalleryViewOpen, setIsGalleryViewOpen] = useState(false);

  useEffect(() => {
    if (!isGalleryViewOpen) {
      window.document.documentElement.style.overflow = "auto";
    } else {
      window.document.documentElement.style.overflow = "hidden";
    }
  }, [isGalleryViewOpen]);

  function handleImageClick() {
    setIsGalleryViewOpen(true);
  }

  const renderGalleryView = () => {
    if (isGalleryViewOpen) {
      return (
        <GalleryView
          src={props.src}
          title={props.title}
          onClose={() => setIsGalleryViewOpen(false)}
        />
      );
    }
    return null;
  };

  return (
    <div>
      <img
        src={props.src}
        className={`${styles.thumbnail} ${props.className}`}
        alt={props.className}
        onClick={handleImageClick}
        title={props.title}
      />
      {renderGalleryView()}
    </div>
  );
}
