import styles from "./Maps.module.scss";
import ClickableImageItem from "./ClickableImageItem.js";

export default function Map1() {
  return (
    <div className={styles.map1}>
      <img
        src="/images/maps/tlocrtkat.webp"
        className={styles.map1}
        alt="tlocrtkat"
      />
      <ClickableImageItem
        src="/images/maps/kat/7_JPG.webp"
        className={styles.img7}
        alt="tlocrtkat.kat_7"
        title="25"
      />
      <ClickableImageItem
        src="/images/maps/kat/8_JPG.webp"
        className={styles.img8}
        alt="tlocrtkat.kat_8"
        title="24"
      />
      <ClickableImageItem
        src="/images/maps/kat/26_JPG.webp"
        className={styles.img26}
        alt="tlocrtkat.kat_13"
        title="1"
      />
      <ClickableImageItem
        src="/images/maps/kat/10_JPG.webp"
        className={styles.img10}
        alt="tlocrtkat.kat_10"
        title="4"
      />
      <ClickableImageItem
        src="/images/maps/kat/9a_JPG.webp"
        className={styles.img9}
        alt="tlocrtkat.kat_9"
        title="19"
      />
      <ClickableImageItem
        src="/images/maps/kat/2_JPG.webp"
        className={styles.img2}
        alt="tlocrtkat.kat_2"
        title="20"
      />
      <ClickableImageItem
        src="/images/maps/kat/3a_JPG.webp"
        className={styles.img3}
        alt="tlocrtkat.kat_3"
        title="29"
      />
      <ClickableImageItem
        src="/images/maps/kat/5_JPG.webp"
        className={styles.img5}
        alt="tlocrtkat.kat_5"
        title="26"
      />
      <ClickableImageItem
        src="/images/maps/kat/4_JPG.webp"
        className={styles.img4}
        alt="tlocrtkat.kat_4"
        title="16"
      />
      <ClickableImageItem
        src="/images/maps/kat/12_JPG.webp"
        className={styles.img12}
        alt="tlocrtkat.kat_12"
        title="13"
      />
      <ClickableImageItem
        src="/images/maps/kat/6_JPG.webp"
        className={styles.img6}
        alt="tlocrtkat.kat_6"
        title="18"
      />
      <ClickableImageItem
        src="/images/maps/kat/11_JPG.webp"
        className={styles.img11}
        alt="tlocrtkat.kat_11"
        title="15"
      />
      <ClickableImageItem
        src="/images/maps/kat/1_JPG.webp"
        className={styles.img1}
        alt="tlocrtkat.kat_1"
        title="27"
      />
      <ClickableImageItem
        src="/images/maps/kat/27_JPG.webp"
        className={styles.img27}
        alt="tlocrtkat.kat_14"
        title="3"
      />
      <ClickableImageItem
        src="/images/maps/kat/28_JPG.webp"
        className={styles.img28}
        alt="tlocrtkat.kat_15"
        title="5"
      />
    </div>
  );
}
