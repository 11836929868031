import styles from "./ImgGroupItems.module.scss";

export default function Item3() {
  return (
    <div className={styles.group}>
      <img
        src="/images/groups/3/3_1.webp"
        className={styles.group3img1}
        alt="img1.group_3"
        title="#26"
      />
      <img
        src="/images/groups/3/3_2.webp"
        className={styles.group3img2}
        alt="img2.group_3"
        title="#8"
      />
      <img
        src="/images/groups/3/3_3.webp"
        className={styles.group3img2}
        alt="img3.group_3"
        title="#6"
      />
      <img
        src="/images/groups/3/3_4.webp"
        className={styles.group3img2}
        alt="img4.group_3"
        title="#12"
      />
    </div>
  );
}
