export default function Close(props) {
  return (
    <svg
      className={props.className}
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="1" y1="18" x2="18" y2="1" stroke="white" strokeWidth="2" />
      <line x1="1" y1="1" x2="18" y2="18" stroke="white" strokeWidth="2" />
    </svg>
  );
}
