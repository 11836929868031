import styles from "./ImgItems.module.scss";

export default function ImgItem7() {
  return (
    <div className={styles.img7}>
      <img
        src="/images/7.webp"
        className={styles.img7}
        alt="img7"
        title="Matija and Lola"
      />
    </div>
  );
}
