import styles from "./TextItems.module.scss";

export default function Item13() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>Lipanj 2017.</b> Nekad tijekom Matejevog godišnjeg srećem Fraja na
          pola Omladinske, pozdravljam ga i produžim. On me sustiže na bicikli i
          započinje sa “Ša ima” a ja odgovaram. Razgovor nakon nekoliko minuta
          odvede do toga da mi kaže kako mu se sviđa izložba i da to mladi danas
          ne znaju cijeniti. Tvrdi da su klinci porasturali sve. On je pokupio
          ostatke i predlaže mi da ga zovem kad i ako želim fotografije natrag.
          Odgovaram kako nema potrebe jer je to prirodni tok toga projekta.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>June 2017</b> Sometime during Matej’s vacation I meet Fraj in the
          middle of Omladinska street, I say hello and move on. He catches up on
          his bike and begins with “What’s up” and I reply. After few minutes
          the conversation leads to him saying that he likes the exhibition and
          that youth these days doesn’t know to appreciate it. Says the kids
          tore it down. He gathered the rest and suggests that I call him when
          and if I want the photos back. I say that there’s no need for that,
          it’s just the flow of the project.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item13}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
