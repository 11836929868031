import styles from "./ImgGroupItems.module.scss";

export default function Item8() {
  return (
    <div className={styles.group}>
      <img
        src="/images/groups/8/8_1.webp"
        className={styles.group8img1}
        alt="img1.group_8"
      />
      <img
        src="/images/groups/8/8_2.webp"
        className={styles.group8img2}
        alt="img2.group_8"
      />
      <img
        src="/images/groups/8/8_3.webp"
        className={styles.group8img2}
        alt="img3.group_8"
      />
    </div>
  );
}
