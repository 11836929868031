import styles from "./VideoGroupItems.module.scss";

export default function Item1() {
  return (
    <div className={styles.group1}>
      <video
        className={styles.group1item1}
        preload="auto"
        autoPlay
        loop
        playsInline
        muted
      >
        <source src="/videos/1_1.mp4" type="video/mp4" />
      </video>

      <video
        className={styles.group1item2}
        preload="auto"
        autoPlay
        loop
        playsInline
        muted
      >
        <source src="/videos/1_2.mp4" type="video/mp4" />
      </video>

      <video
        className={styles.group1item3}
        preload="auto"
        autoPlay
        loop
        playsInline
        muted
      >
        <source src="/videos/1_3.mp4" type="video/mp4" />
      </video>
    </div>
  );
}
