import styles from "./VideoGroupItems.module.scss";

export default function Item2() {
  return (
    <div className={styles.group2}>
      <video className={styles.group2item1} autoPlay loop playsInline muted>
        <source src="/videos/2_1.mp4" type="video/mp4" />
      </video>

      <video className={styles.group2item2} autoPlay loop playsInline muted>
        <source src="/videos/2_2.mp4" type="video/mp4" />
      </video>
    </div>
  );
}
