import styles from "./TextItems.module.scss";

export default function Item1() {
  function getCroText() {
    return (
      <div className={styles.cro}>
        <p>
          &nbsp; U nedovršenoj kući na rubu Pule ostavila sam nepregledno mnogo
          projekcija kroz naše cijelo istovremeno postojanje.
        </p>
        <p>
          &nbsp; U svim oblicima, samo dvije parcele dalje od one u kojoj sam
          odrasla, djetinjstvo gleda na poznato kao stalnost. Strah je opasnost.
        </p>
        <p>
          &nbsp; Svi oni kojima je Disco Pavle ikada pružao utočište u
          ranjivostima raznih priroda prošli su kroz njega kao ljudi kroz igdje.
        </p>
        <p>
          &nbsp; Dok se nestalni hologram Mie Mars gubi u cyberprostoru, ovdje
          ga se materijalno nastoji oformiti u oblik pop-up izložbe kako bi se
          izazvala komunikacija s nepoznatim. Sudionici fotografije uništavaju,
          trgaju, kradu i vraćaju u moj vrt.
        </p>
        <p>
          &nbsp; Ne poznajemo se, koliko dodirnih točaka osim ruševine imamo
          uopće? Jesam li ja to gdje odrastam? Tko su svi ti ljudi koji prolaze
          toliko blizu? Nakon čega ovdje dolaze? Hoće li im nedostajati ta
          nedovršena ruševina?
        </p>
      </div>
    );
  }

  function getEngText() {
    return (
      <div className={styles.eng}>
        <p>
          &nbsp; In an unfinished house in the outskirts of Pula I left
          endlessly numerous projections during the whole period of our mutual
          existence.
        </p>

        <p>
          &nbsp; In every shape, only two parcels away from the one I grew up
          at, childhood looks at common as permanent. Fear is danger.
        </p>

        <p>
          &nbsp; All of those to whom Disco Pavle ever gave a shelter in
          vulnerabilities of various natures went through it like people through
          anywhere.
        </p>

        <p>
          &nbsp; While the unstable hologram of Mia Mars gets lost in the
          cyberspace, here it is striven to physically form it as an exhibition
          in order to communicate with the unknown. Participants destroy, tear,
          steal and return the photos to my garden.
        </p>

        <p>
          &nbsp; We don’t know each other, how many things in common do we even
          have apart the ruins? Am I where I grew up at? Who are those living so
          close? After what do they come here? Will they miss that unfinished
          ruin?
        </p>
      </div>
    );
  }

  return (
    <div className={styles.item1}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
