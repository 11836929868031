import styles from "./TextItems.module.scss";

export default function Item11() {
  function getCroText() {
    return (
      <ul className={styles.cro}>
        <li>
          <b>11.1.2017.</b> Golema betonska ploča koja prekriva gotovo cijeli
          posjed je očišćena i sav je građevinski otpad bačen u okolno grmlje.
          Stanje preostalog Aftera nepromijenjeno.
        </li>
      </ul>
    );
  }

  function getEngText() {
    return (
      <ul className={styles.eng}>
        <li>
          <b>11 January 2017</b> Huge concrete bloc that covers nearly the whole
          area of the estate is cleaned and every bit of construction waste has
          been thrown in the bushes. <i>After</i> stays unchanged.
        </li>
      </ul>
    );
  }

  return (
    <div className={styles.item11}>
      {getCroText()}
      {getEngText()}
    </div>
  );
}
